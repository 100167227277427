import React from 'react';
import Video from './assets/videos/InShot_20230525_225957640.mp4';
import "./App.css"
function BGVideo() {
  return (
    <div class="video-container">
      <video src={Video} type="video/mp4" autoPlay loop muted />
    </div>
  );
}
export default BGVideo;
